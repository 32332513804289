import { defineStore } from 'pinia';

export const useInternalStore = defineStore('internalStore', {
  state: () => ({
    isInternalUser: false,
  }),
  getters: {
    getIsInternalUser: (state): boolean => !!state.isInternalUser,
  },
  actions: {
    setIsInternalUser(isInternalUser: boolean): void {
      this.isInternalUser = isInternalUser;
    },
  },
});
